import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Container } from "react-bootstrap"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import FeatureList from "../components/featureList"
import CallIcon from "../components/callIcon"
import QuoteForm from "../components/quoteForm"

import InsuranceImage from "../images/stock-image-27.jpg"
import Stock1Src from "../images/stock-image-6.jpg"
import Stock2Src from "../images/stock-image-29.jpg"
import Stock3Src from "../images/stock-image-28.jpg"

const PrivateInsurance = () => {
  const { t } = useTranslation()

  const features = [
    {
      title: t("pi_f0_title"),
      description: t("pi_f0_desc"),
      href: null,
      image: Stock1Src,
      imageFirst: true,
    },
    {
      title: t("pi_f1_title"),
      description: t("pi_f1_desc"),
      href: null,
      image: Stock2Src,
      imageFirst: false,
    }
  ]

  return (
    <Layout>
      <SEO title={t("private_insurance")} />

      <div className="dark-container">
        <InsuranceDescription
          title={t("private_insurance_title")}
          subtitle={t("private_insurance_subtitle")}
          actions={
            <>
              <a
                href={`tel:${APREMIA_PHONE}`}
                className="btn btn-primary btn-sm"
              >
                <CallIcon /> {APREMIA_PHONE_FORMATTED}
              </a>
              <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
                {t("we_call_you")}
              </a>
            </>
          }
          image={InsuranceImage}
          imageMobile={InsuranceImage}
          imageTablet={InsuranceImage}
        />
      </div>

      <div className="bg-white py-md-6">
        <Container className="gx-0 gx-md-3">
          <FeatureList list={features} />
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default PrivateInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
