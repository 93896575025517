import React from "react"
import classNames from "classnames"
import { Row, Col } from "react-bootstrap"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import StockImage from "./stockImage"

const FeatureList = props => {
  const { list, normalFontWeight } = props

  const { t } = useTranslation()

  if (!list) {
    return null
  }

  return list.map((item, index) => (
    <div key={index}>
      <Row className="gx-0 gx-md-3">
        <Col xs="12" lg={{ span: 6, order: item.imageFirst ? 0 : 1 }}>
          <StockImage src={item.image} />
        </Col>

        <Col
          xs="12"
          lg={{ span: 6, order: item.imageFirst ? 1 : 0 }}
          className="d-flex flex-column justify-content-center equalize-padding-x px-md-4 px-lg-6 pb-4 pb-md-0"
        >
          {!!item.title && <h3>{item.title}</h3>}
          {!!item.description && (
            <p
              className={classNames("render-tags", {
                "fw-light": !normalFontWeight,
              })}
            >
              {item.description}
            </p>
          )}
          {!!item.href && <Link to={item.href} className="text-danger">&raquo; {t("more_info")}</Link>}
        </Col>
      </Row>
      {index < list.length - 1 && <hr className="d-none d-md-block my-5" />}
    </div>
  ))
}

export default FeatureList
